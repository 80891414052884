import axios from "axios";
import { tokenGlobal } from "../App";
import { applicationType } from "../applicationType";

export let apiDomainUrlSocket = "https://app.funner.co.il";
export let apiDomenUrl = "https://app.funner.co.il";
export let apiDomainUrlMessages = "https://send.funner.co.il";
export let webAppDomain = "https://web.funner.co.il";
// export let webAppDomain = "http://localhost:3001";

const domainByType = {
  app: "https://app.funner.co.il",
  messages: "https://send.funner.co.il",
  config: "https://history.funner.co.il",
  socket: "https://app.funner.co.il",
  history: "https://history.funner.co.il",
  // history: "https://testapi.funner.co.il",
  fireberry: "https://history.funner.co.il",
  test: "https://testapi.funner.co.il",
  priority: "https://prioritysdk.funner.co.il",
};

async function getTokenFromChromeStorage() {
  const result = await new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    chrome.storage.local.get("whatsapp-token", (data) => {
      resolve(data);
    });
  });
  return result["whatsapp-token"];
}

const endpointControllers = {};

export async function UseFetchGet(
  endpoint,
  params,
  useController,
  isAbortable = false,
  domainType,
  cancelToken = null,
) {
  let token = null;
  if (tokenGlobal) {
    token = tokenGlobal;
  } else {
    token =
      applicationType === "extension"
        ? await getTokenFromChromeStorage()
        : localStorage.getItem("whatsapp-token");
  }

  if (!token) return;

  const usingDomain =
    domainType && domainByType[domainType]
      ? domainByType[domainType]
      : "https://app.funner.co.il";
  let paramsString = "";
  if (params) {
    for (const [key, value] of Object.entries(params)) {
      paramsString += `${key}=${value}&`;
    }
    paramsString = paramsString.slice(0, -1);
  }

  const controllerKey = `${endpoint}`;

  // if (endpointControllers[controllerKey] && isAbortable) {
  //   endpointControllers[controllerKey].abort();
  //   console.log(`Request ${endpoint} aborted`);
  // }

  const controller = new AbortController();
  endpointControllers[controllerKey] = controller;

  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  try {
    const response = await axios.get(
      paramsString
        ? `${usingDomain}${endpoint}?${paramsString}`
        : `${usingDomain}${endpoint}`,
      {
        signal: controller.signal,
        cancelToken: cancelToken,
      },
    );
    return response;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log(
        `Request ${endpoint} canceled due to new request to the same endpoint`,
        error.message,
      );
    } else {
      console.error("Error during fetch:", error.message);
    }
    return error;
  }
}

export async function UseFetchGetAnonymos(
  endpoint,
  params,
  controller,
  usingNote = "usingPlaceholder",
  domainType,
) {
  const usingDomain =
    domainType && domainByType[domainType]
      ? domainByType[domainType]
      : "https://app.funner.co.il";
  let paramsString = "";

  if (params) {
    for (const [key, value] of Object.entries(params)) {
      paramsString += `${key}=${value}&`;
    }
    paramsString = paramsString.slice(0, -1);
  }

  if (controller) {
    const res = await axios.get(`${usingDomain}${endpoint}?${paramsString}`, {
      signal: controller.signal,
    });
    ////console.log(res, endpoint)
    return res;
  } else {
    const res = await axios.get(`${usingDomain}${endpoint}?${paramsString}`);
    ////console.log(res, endpoint)
    return res;
  }
}

export async function UseFetchPost(endpoint, params, domainType) {
  let token = null;
  if (tokenGlobal) {
    token = tokenGlobal;
  } else {
    token =
      applicationType === "extension"
        ? await getTokenFromChromeStorage()
        : localStorage.getItem("whatsapp-token");
  }

  if (!token) return;

  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  const usingDomain =
    domainType && domainByType[domainType]
      ? domainByType[domainType]
      : "https://app.funner.co.il";

  let response;
  if (params) {
    response = await axios.post(`${usingDomain}${endpoint}`, params);
  } else {
    response = await axios.post(`${usingDomain}${endpoint}`);
  }

  return response;
}

export async function UseFetchPostAnonymos(endpoint, params, domainType) {
  const usingDomain =
    domainType && domainByType[domainType]
      ? domainByType[domainType]
      : "https://app.funner.co.il";

  let response = await axios.post(`${usingDomain}${endpoint}`, params);
  return response;
}

export async function UseFetchPostForm(endpoint, formData, domainType) {
  const usingDomain =
    domainType && domainByType[domainType]
      ? domainByType[domainType]
      : "https://app.funner.co.il";

  let response = await fetch(`${usingDomain}${endpoint}`, {
    method: "POST",
    body: formData,
  });

  return response.json();
}